<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Job Content Type Data Master</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                @click="openFormUpdateContentType = true">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <types-list :contentTypes="typeLists.content_types" />
    <v-dialog
      v-model="openFormUpdateContentType"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Job Content Type Master</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="typeData.name"
              label="Content Types name"
              outlined
              required />

            <v-switch
              v-model="typeData.isDefault"
              label="Default"></v-switch>

            <v-checkbox
              v-model="typeData.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="create">
            Create
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormUpdateContentType = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'JobMasterData',
  components: {
    TypesList: () => import('@/components/MasterData/masterDataContentTypeList.vue'),
  },
  data() {
    return {
      submenu: [
        { text: 'Create Job Content Type', icon: 'mdi-folder-text-outline' },
      ],
      openFormUpdateContentType: false,
      typeData: {
        name: '',
        isDefault: false,
        activated: true,
      }
    };
  },
  computed: {
    ...mapGetters({
      typeLists: 'masterData/GET_CONTENTTYPES'
    })
  },
  async created() {
    await this.$store.dispatch('masterData/GET_CONTENTTYPES_ALL');
  },
  methods: {
    async create() {
      await this.$store.dispatch('masterData/CREATE_CONTENTTYPE', this.typeData);

      this.typeData.name = '';
      this.typeData.isDefault = false;
      this.typeData.activated = true;

      this.openFormUpdateContentType = false;
    }
  }
};
</script>
